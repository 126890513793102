import App from "./App.vue";
import * as Vue from "vue";
import { createPinia } from "pinia";
import i18n from "@/utils/i18n";
import router from "./router";
import ToastPlugin from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";
// Import one of the available themes

import mixins from "./config/mixins";

import "@/assets/scss/main.scss";
import { useIndexStore } from "@/store";

export const app = Vue.createApp(App);
const pinia = createPinia();
app.use(ToastPlugin);
app.use(pinia);
app.use(i18n);
app.mixin(mixins);
app.use(router);

app.mount("#app");

useIndexStore().connect();

app.config.globalProperties.$store = useIndexStore();
export const indexStore = useIndexStore(pinia);
window.vueApp = app;

export default app;
