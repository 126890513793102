import { createI18n } from "vue-i18n";

const messages = {
  fr: {
    "Follow the profile you want anonymously and download all content!":
      "Anasaya",
    "Example: @zachking": "Anasaya",
    Download: "Anasaya",
    Share: "Anasaya",
    "Privacy and Policy": "Anasaya",
    "Terms and Use": "Anasaya",
    "Be-A Stalker!": "Anasaya",
    "Unlimited watch & download": "Anasaya",
    Stalk: "Anasaya",
    Categories: "Anasaya",
  },
};

const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages,
});

export default i18n;
