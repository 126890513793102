import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/SearchView.vue"),
  },
  {
    path: "/suggestion",
    name: "suggestion",
    component: () =>
      import(
        /* webpackChunkName: "suggestion" */ "../views/SuggestionView.vue"
      ),
  },
  {
    path: "/profile/:username",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/terms-and-use",
    name: "terms-and-use",
    component: () =>
      import(
        /* webpackChunkName: "terms-and-use" */ "../views/TermsAndUseView.vue"
      ),
    meta: {},
  },
  {
    path: "/privacy-and-policy",
    name: "privacy-and-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-and-policy" */ "../views/PrivacyAndPolicyView.vue"
      ),
    meta: {
      hideNavigation: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
