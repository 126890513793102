import { Media } from "@capacitor-community/media";

export const getAlbumIdentifier = async () => {
  const albumName = "InSave Photos";
  const { albums } = await Media.getAlbums();
  const album = albums.find((a) => a.name === albumName);
  if (album) {
    console.log("Album already exists: ", album);
    return album.identifier;
  }
  await Media.createAlbum({ name: albumName });
  console.log("Created album: ", albumName);

  return getAlbumIdentifier();
};
