import { CapacitorHttp } from "@capacitor/core";
import { Device } from "@capacitor/device";

const baseURL = "https://instagram-downloader.noapi.space/api";

const api = {
  async defaultOptions() {
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    return {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${deviceId.identifier}`,
        "x-device-info": JSON.stringify(deviceInfo),
        "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  },
  async connect(params) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/connect`,
      params: { ...params },
    });
  },
  async search(username) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/search`,
      params: { username: username },
    });
  },
  async getProfile(username) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/profile`,
      params: { username: username },
    });
  },
  async getStoryByUsername(username) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/download/story`,
      params: { username: username },
    });
  },
  async getSuggestion(text) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/suggestion`,
      params: { text: text },
    });
  },
  async getHighlightsByUsername(username) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/download/highlights`,
      params: { username: username },
    });
  },
  async downloadAll(url) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/download/auto`,
      params: { url: url },
    });
  },
  async downloadProfilePhoto(username) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/download/profile-picture`,
      params: { username: username },
    });
  },
  async getMyProfile(sessionid) {
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    // sessionid =
    //   "62206690221%3Amn96pet2ks6okb%3A7%3AAYd1152QTg9SnL4YfOJwtPYI6AN6Ll6VnLEK1ytkIw";
    return CapacitorHttp.post({
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${deviceId.identifier}`,
        "x-device-info": JSON.stringify(deviceInfo),
        "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      url: `${baseURL}/sessions`,
      data: {
        id: sessionid,
      },
    });
  },
};
export default api;
