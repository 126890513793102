<template>
  <div class="media-area">
    <div
      class="close-area"
      @click="
        mediaData = null;
        mediaType = null;
      "
    >
      <img src="@/assets/images/icons/close-dark.png" alt="" />
    </div>
    <div class="data-area">
      <div class="profile-hoto" v-if="mediaType === 'GraphProfile'">
        <img :src="convertInstagramURL(mediaData.url)" alt="" />
      </div>
      <div class="single-item" v-if="mediaType === 'GraphImage'">
        <v-lazy-image :src="convertInstagramURL(mediaData.items[0].url)" />
      </div>
      <div class="video-item" v-if="mediaType === 'GraphVideo'">
        <video
          :poster="mediaData.thumbnail"
          loop
          autoplay
          webkit-playsinline
          playsinline
        >
          <source
            :src="convertInstagramURL(mediaData.items[0].url)"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="multiple-item" v-if="mediaType === 'GraphSidecar'">
        <div class="slider">
          <template v-for="(item, index) in mediaData.items">
            <div class="slider-item" :key="index" v-if="activeIndex === index">
              <v-lazy-image :src="convertInstagramURL(item.url)" />
            </div>
          </template>
          <div class="slider-navigation">
            <div
              class="navigation-button left"
              v-if="activeIndex !== 0"
              @click="--activeIndex"
            >
              <img src="@/assets/images/icons/left-chevron.png" alt="" />
            </div>
            <div
              class="navigation-button right"
              v-if="activeIndex !== mediaData.items.length - 1"
              @click="++activeIndex"
            >
              <img src="@/assets/images/icons/right-chevron.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-area">
      <div class="buttons">
        <button class="button primary" @click="mediaAction()">
          <img src="@/assets/images/icons/download.png" alt="" />
          <span> {{ $t("Save to Gallery") }}</span>
        </button>
        <!-- <button class="button secondary">{{ $t("Share") }}</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
  name: "MediaArea",
  components: {
    VLazyImage,
  },
  data() {
    return {
      mappedMediaData: null,
      activeIndex: 0,
    };
  },
  methods: {
    async mediaAction() {
      let media = {
        type: this.mediaType,
        data: this.mediaData,
        index: this.activeIndex,
      };
      this.savePost(media);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-area {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
  display: flex;
  align-items: center;

  .close-area {
    position: absolute;
    right: 5vw;
    top: 5vh;
    z-index: 1;
    background-color: rgba($white, 0.5);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .data-area {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .video-item {
      height: 100vh;
      width: 100vw;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .action-area {
    position: absolute;
    bottom: 42px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    .buttons {
      display: flex;
      width: 100%;
      margin: 0 24px;
      justify-content: space-between;
      button {
        width: 100%;
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
}

.slider {
  position: relative;
  &-item {
    height: 100vh;
    img {
      object-fit: cover;
    }
  }
  .slider-navigation {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    .navigation-button {
      background-color: $light;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      img {
        width: 24px;
        height: 24px;
      }
      &.left {
        position: absolute;
        left: 24px;
      }
      &.right {
        position: absolute;
        right: 24px;
      }
    }
  }
}
</style>
