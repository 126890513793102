import { mapState, mapWritableState, mapActions } from "pinia";
import { useIndexStore } from "@/store/index";
export default {
  computed: {
    ...mapState(useIndexStore, [
      "appName",
      "mediaType",
      "safeAreaInsets",
      "isReview",
      "sessionid",
      "myProfile",
      "username",
    ]),

    ...mapWritableState(useIndexStore, [
      "mediaData",
      "mediaType",
      "showSideMenu",
      "isAppLoading",
      "activeUser",
      "searchResult",
      "stories",
      "savePost",
      "isInstagramLoggedIn",
    ]),
  },
  methods: {
    ...mapActions(useIndexStore, ["downloadVideo", "payment"]),
    convertToInternationalCurrencySystem(labelValue) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
        : Math.abs(Number(labelValue));
    },

    convertInstagramURL(url) {
      let cdnUrl = "https://guardlink.eislambey.workers.dev/?proxyUrl=";
      let fullUrl = cdnUrl + encodeURIComponent(url);
      return fullUrl;
    },
  },
};
