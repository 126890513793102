import api from "@/utils/api";

import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { Media } from "@capacitor-community/media";
import { Capacitor } from "@capacitor/core";
import { getAlbumIdentifier } from "@/utils/media";
// import { sessionid, myProfile } from "@/mock";

export const useIndexStore = defineStore("useIndexStore", {
  state: () => ({
    appName: "InSave",
    user: null,
    isReview: true,
    sessionid: null,
    myProfile: null,
    username: null,
    activeUser: {
      profileInfo: null,
      profileHighlights: null,
    },
    mediaType: null,
    mediaData: null,
    showSideMenu: false,
    isAppLoading: false,
    searchResult: [],
    stories: [],
    safeAreaInsets: 0,
    isInstagramLoggedIn: false,
  }),

  actions: {
    async connect() {
      this.isAppLoading = true;
      return await api
        .connect()
        .then((res) => {
          this.user = res?.data?.data?.user;
          // this.isProd = res?.data?.data?.prod;
          // this.isReview = res?.data?.data?.prod;
          this.getCookie("username").then((username) => {
            if (username) {
              this.username = username;
              this.isInstagramLoggedIn = true;
            }
          });
        })
        .finally(() => (this.isAppLoading = false));
    },
    search(username) {
      this.isAppLoading = true;
      return api
        .search(username)
        .then((res) => {
          if (res.data.data) {
            this.searchResult = res.data.data;
          }
        })
        .finally(() => (this.isAppLoading = false));
    },
    getProfile(username) {
      this.isAppLoading = true;
      return api
        .getProfile(username)
        .then((res) => {
          this.activeUser = { ...this.activeUser, profileInfo: res.data.data };
          this.isAppLoading = false;
          console.log("furkan", res.data.data);
        })
        .finally(() => {
          this.isAppLoading = false;
        });
    },
    getSuggestion(text) {
      this.isAppLoading = true;
      return api
        .getSuggestion(text)
        .then((res) => {
          return res.data.data;
        })
        .finally(() => (this.isAppLoading = false));
    },
    getUserStories(username) {
      this.isAppLoading = true;
      return api
        .getStoryByUsername(username)
        .then((res) => {
          this.stories = res.data.data;
        })
        .finally(() => (this.isAppLoading = false));
    },
    getMyProfile(sessionid) {
      this.isAppLoading = true;
      return api
        .getMyProfile(sessionid)
        .then((res) => {
          this.myProfile = res.data;
          console.log("furkan", res);
          this.setCookie("username", res.data.username);
          this.username = res.data.username;
        })
        .finally(() => (this.isAppLoading = false));
    },
    getHighlightsByUsername(username) {
      this.isAppLoading = true;
      return api
        .getHighlightsByUsername(username)
        .then((res) => {
          this.activeUser = {
            ...this.activeUser,
            profileHighlights: res.data.data.highlights,
          };
        })
        .finally(() => (this.isAppLoading = false));
    },
    downloadProfilePhoto(username) {
      this.isAppLoading = true;
      return api
        .downloadProfilePhoto(username)
        .then((res) => {
          if (res.status === 200) {
            this.mediaType = "GraphProfile";
            this.mediaData = res.data.data;
          } else {
            alert(res.data.title);
          }
        })
        .finally(() => {
          this.isAppLoading = false;
        });
    },
    async downloadAll(url) {
      this.isAppLoading = true;
      return await api
        .downloadAll(url)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.type === "story") {
              this.mediaType = "GraphVideo";
              this.mediaData = {
                items: [
                  {
                    url: res.data.data.result.url,
                    thumbnail: res.data.data.result.url,
                  },
                ],
              };
            } else {
              this.mediaType = res.data.data.result.type;
              this.mediaData = res.data.data.result;
            }
          } else {
            alert(res.data.violations[0].title);
          }
        })
        .finally(() => {
          this.isAppLoading = false;
        });
    },

    logoutInstagram() {
      this.myProfile = null;
      this.sessionid = null;
      this.username = null;
      this.removeCookie("sessionid");
      this.removeCookie("username");
      this.showSideMenu = false;
      this.isInstagramLoggedIn = false;
    },

    async saveURL(media) {
      this.isAppLoading = true;

      const options = {
        path: media.url,
      };

      if (media.type === "video") {
        const timestamp = new Date().getTime();
        options.path += `&video-${timestamp}.mp4`;
      }

      if (Capacitor.getPlatform() === "android") {
        options.albumIdentifier = await getAlbumIdentifier();
      }

      try {
        await Media.savePhoto(options);
        alert("Saved to gallery.");
      } catch (error) {
        console.log("Media.savePhoto error", error);
      } finally {
        this.isAppLoading = false;
      }
    },
    async savePost(media) {
      let path = null;
      this.isAppLoading = true;
      if (media.type === "GraphSidecar") {
        console.log("media", media.data.items);
        path = media.data.items[media.index].url;
      } else {
        path = media.data.items[0].url;
      }
      if (media.type === "GraphVideo") {
        await Media.saveVideo({
          path: `${path}&video.mp4`,
        })
          .then(() => {
            alert("Saved to gallery.");
          })
          .catch((err) => {
            console.log("ee", err);
          })
          .finally(() => {
            this.isAppLoading = false;
          });
      } else {
        await Media.savePhoto({
          path: path,
        })
          .then(() => {
            alert("Saved to gallery.");
          })
          .finally(() => {
            this.isAppLoading = false;
          });
      }
    },
    async setCookie(key, value) {
      await Preferences.set({
        key: key,
        value: JSON.stringify(value),
      }).then(() => {
        return true;
      });
    },

    async getCookie(key) {
      const { value } = await Preferences.get({ key: key });
      if (value) {
        return JSON.parse(value);
      }
    },
    async removeCookie(key) {
      await Preferences.remove({ key: key });
    },
  },
});
