<template>
  <div class="loading-wrapper">
    <div class="loading">
      <img src="@/assets/logo.png" alt="" />
    </div>
  </div>
  <!-- <div class="loading-wrapper">
    <div class="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div> -->
</template>
<script>
export default {
  name: "TLoading",
};
</script>
<style lang="scss" scoped>
.loading-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgba($dark, 0.95);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10000000000000;
  overflow: hidden;
  .loading {
    display: flex;
    margin: auto;
    img {
      width: 100px;
      animation: zoom-in-zoom-out 1s ease infinite;
      border-radius: 16px;
    }
    span {
      display: block;
      width: 20px;
      height: 20px;
      background: #f1ac37;
      border-radius: 50%;
      margin: 0 5px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  .loading span:nth-child(2) {
    background: #dc813a;
  }

  .loading span:nth-child(3) {
    background: #e65f4a;
  }

  .loading span:nth-child(4) {
    background: #ed4356;
  }

  .loading span:not(:last-child) {
    animation: animate 1.5s linear infinite;
  }
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes animate {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(30px);
    }
  }

  .loading span:last-child {
    animation: jump 1.5s ease-in-out infinite;
  }

  @keyframes jump {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(10px, -10px);
    }
    20% {
      transform: translate(20px, 10px);
    }
    30% {
      transform: translate(30px, -50px);
    }
    70% {
      transform: translate(-150px, -50px);
    }
    80% {
      transform: translate(-140px, 10px);
    }
    90% {
      transform: translate(-130px, -10px);
    }
    100% {
      transform: translate(-120px, 0);
    }
  }
}
</style>
