<template>
  <div class="side-menu-wrapper" @click.self="showSideMenu = false">
    <div class="side-menu">
      <div
        class="side-menu-top"
        :style="[`padding-top: ${safeAreaInsets.top}px`]"
      >
        <div class="side-menu-header">
          <span class="title">{{ $t("Welcome to ") + appName }}</span>
          <div class="close-area" @click="showSideMenu = false">
            <img src="@/assets/images/icons/menu.png" alt="" />
          </div>
        </div>
        <ul class="side-menu-list">
          <!-- <li class="side-menu-list-item">
            <a class="side-menu-list-item-link primary">
              {{ $t("Get Premium") }}
            </a>
          </li> -->
          <li class="side-menu-list-item">
            <router-link
              class="side-menu-list-item-link"
              :to="{ name: 'privacy-and-policy' }"
              @click="showSideMenu = false"
            >
              {{ $t("Privacy and Policy") }}
            </router-link>
          </li>
          <li class="side-menu-list-item">
            <router-link
              class="side-menu-list-item-link"
              :to="{ name: 'terms-and-use' }"
              @click="showSideMenu = false"
            >
              {{ $t("Terms and Use") }}
            </router-link>
          </li>
          <li class="side-menu-list-item">
            <a class="side-menu-list-item-link">
              {{ $t("Rate us") }}
            </a>
          </li>
          <li class="side-menu-list-item">
            <a
              :href="`mailto:insaveapp@gmail.com?&subject=${deviceId}&body=Don't Change Subject.`"
              target="_blank"
              class="side-menu-list-item-link"
              :body="'deviceId'"
            >
              {{ $t("Support") }}
            </a>
          </li>
          <li
            class="side-menu-list-item"
            v-if="isInstagramLoggedIn && username"
            @click="logout()"
          >
            <a class="side-menu-list-item-link">
              {{ $t("Logout") }}
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="side-menu-bottom">
        <PremiumCard />
      </div> -->
    </div>
  </div>
</template>

<script>
import { useIndexStore } from "@/store";
import { Device } from "@capacitor/device";

// import PremiumCard from "./PremiumCard.vue";
export default {
  name: "SideMenu",
  data() {
    return {
      deviceId: "111",
    };
  },
  // components: { PremiumCard },
  async mounted() {
    await this.getDeviceID();
  },
  methods: {
    async getDeviceID() {
      const device = await Device.getId();
      this.deviceId = device.identifier;
    },
    logout() {
      useIndexStore().logoutInstagram();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu-wrapper {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba($dark, 0.7);
  // filter: blur(3px);
  .side-menu {
    width: 80%;
    height: 100%;
    background-color: $dark;
    border-right: 2px solid $dark-2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: openSideMenu 0.5s;

    &-top {
      display: flex;
      flex-direction: column;
      margin: $gap-m;

      .side-menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 22px;
          font-weight: 600;
        }
        .close-area {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .side-menu-list {
        margin-top: 10vh;
        &-item {
          &-link {
            display: flex;
            color: $white;
            font-size: 18px;
            font-weight: 500;
            padding: 18px 0;
            &.primary {
              color: $primary;
            }
          }
        }
      }
    }
    &-bottom {
      margin: $gap-m;
    }
  }
  .side-menu-bg {
    width: 20%;
    height: 100%;
    background-color: rgba($dark, 0.7);
    filter: blur(3px);
  }
}

@keyframes openSideMenu {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 0;
  }
}
</style>
