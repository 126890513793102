<template>
  <Transition>
    <div class="page" :class="{ 'full-h': $route.meta.hideNavigation }">
      <router-view :style="[`padding-top: ${safeAreaInsets.top}px`]" />
    </div>
  </Transition>

  <navigation v-if="!$route.meta.hideNavigation" />
  <media-area v-if="mediaData" />
  <side-menu v-if="showSideMenu" />
  <loading v-if="isAppLoading" />
</template>

<script>
import Navigation from "@/components/Common/Navigation.vue";
import MediaArea from "@/components/Common/MediaArea.vue";
import SideMenu from "@/components/Common/SideMenu.vue";
import Loading from "@/components/Common/Loading.vue";
import { SafeArea } from "capacitor-plugin-safe-area";

import { useIndexStore } from "./store";
export default {
  components: {
    Navigation,
    MediaArea,
    SideMenu,
    Loading,
  },

  async mounted() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (insets.top === 0) {
        insets.top = 32;
      }
      useIndexStore().safeAreaInsets = insets;
    });
  },
};
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
