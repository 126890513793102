<template>
  <div class="navigation">
    <ul class="navigation-list">
      <li class="navigation-list-item">
        <router-link class="navigation-list-item-link" :to="{ name: 'home' }">
          <img
            v-if="$route.name === 'home'"
            src="@/assets/images/icons/navigation/home-light.svg"
          />
          <img v-else src="@/assets/images/icons/navigation/home-dark.svg" />
          <span class="dot"></span>
        </router-link>
      </li>
      <li class="navigation-list-item" v-if="!isReview">
        <router-link class="navigation-list-item-link" :to="{ name: 'search' }">
          <img
            v-if="$route.name === 'search'"
            src="@/assets/images/icons/navigation/search-light.png"
          />
          <img v-else src="@/assets/images/icons/navigation/search-dark.png" />
          <span class="dot"></span>
        </router-link>
      </li>
      <li class="navigation-list-item" v-if="username">
        <router-link
          class="navigation-list-item-link"
          :to="{
            name: 'profile',
            params: { username: username },
          }"
        >
          <img
            v-if="$route.name === 'profile'"
            src="@/assets/images/icons/navigation/profile-light.svg"
          />
          <img v-else src="@/assets/images/icons/navigation/profile-dark.svg" />
          <span class="dot"></span>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <div class="navigation-list-item-link" @click="showSideMenu = true">
          <!-- <img src="@/assets/images/icons/navigation/profile-light.svg" /> -->
          <img src="@/assets/images/icons/navigation/settings-dark.svg" />
          <span class="dot"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "INavigation",
};
</script>

<style lang="scss" scoped>
.navigation {
  background-color: $dark;
  border-top: 1px solid $dark-2;
  height: $navigation;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  &-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 0 $gap-m;
    &-item {
      &-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 16px;
        img {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
        span {
          background-color: $dark;
          width: 5px;
          height: 5px;
          border-radius: 4px;
        }
        &.router-link-active {
          span {
            background-color: $white;
          }
        }
      }
    }
  }
}
</style>
